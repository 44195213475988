import { lighten } from '@mui/material';
import { grey } from '@mui/material/colors';
import { ComponentTheme } from '.';

export const dataDisplayTheme: ComponentTheme = {
  MuiAvatar: {
    styleOverrides: {
      root: {
        '.MuiAvatar-img': {
          objectPosition: 'top',
        },
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        background: 'transparent',
      },
    },
  },
  MuiTableHead: {
    styleOverrides: {
      root: ({ theme: { palette } }) => ({
        background: palette.mode === 'light' ? lighten(palette.primary.light, 0.8) : grey['800'],

        '.MuiTableCell-root,.MuiTableSortLabel-root': {
          // color: theme.palette.primary.main,
        },
      }),
    },
  },
};
