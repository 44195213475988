import { ComponentTheme } from '.';

export const navigationTheme: ComponentTheme = {
  MuiTabs: {
    styleOverrides: {
      flexContainer: {
        '.MuiButtonBase-root': {
          minWidth: 20,
          maxWidth: 'max-content',
          textTransform: 'none',
          fontWeight: 600,
        },
      },
    },
  },
};
