import { lighten } from '@mui/material';
import { ComponentTheme } from '.';

export const inputsTheme: ComponentTheme = {
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: 'none',
        fontWeight: 'normal',
        '.MuiChip-root': {
          height: '17px',
        },
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        // '.MuiInputBase-input:not(.MuiInputBase-inputSizeSmall)': { padding: '13px 14px;' },
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      filled: ({ ownerState: { color }, theme: { palette } }) => {
        let backgroundColor: any;
        switch (color) {
          case 'success':
            backgroundColor = lighten(palette.success.main, 0.1);
            break;

          case 'error':
            backgroundColor = lighten(palette.error.light, 0.2);
            break;

          case 'info':
            backgroundColor = lighten(palette.info.light, 0.2);
            break;

          case 'warning':
            backgroundColor = lighten(palette.warning.main, 0.1);
            break;

          case 'secondary':
            backgroundColor = '#7e57c2';
            break;
        }

        return {
          backgroundColor,
          fontWeight: 500,
        };
      },
    },
  },
};
