import { ComponentTheme } from '.';

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    dashed: true;
  }
}

export const feedbackTheme: ComponentTheme = {
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        padding: '16px 24px',
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: '10px',
        boxShadow: '0px 4px 20px rgba(7, 26, 79, 0.10)',
      },
    },
    variants: [
      {
        props: { variant: 'dashed' },
        style: ({ theme }) => ({
          borderStyle: 'dashed',
          borderWidth: 1,
        }),
      },
    ],
  },
};
