import { THEMES } from '@dfl/mui-react-common';
import { colors, createTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { components, typography } from './themes';

export const RED = '#d32f2f';
export const GREEN = '#4caf50';
export const GRAY = '#646D82';
export const REDT = '#CF262F';
export const DARK_BLUE = '#18354B';
export const LIGHT_BLUE = '#4CA2E0';
export const DARK_GREEN = '#006000';

export const WARNING = colors.deepOrange['500'];

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary'];
  }

  interface PaletteOptions {
    neutral?: PaletteOptions['primary'];
  }
}

// Extend color prop on components
declare module '@mui/material/Chip' {
  export interface ChipPropsColorOverrides {
    opacity: true;
  }
}
export const common = createTheme({
  components,
  typography,
});

const LIGHT = {
  ...common,
  palette: {
    mode: THEMES.LIGHT,
    accepted: common.palette.augmentColor({
      color: {
        main: '#d3c052',
        contrastText: '#ffffff',
      },
      name: 'accepted',
    }),
    sidebar: {
      background: '#101E52',
      color: '#BAD6EB',
      activeColor: '#fff',
      active: '#4F6595',
    },
    spaceSelector: '#f3f4f9',
    primary: {
      dark: '#071A4F',
      main: '#344EAD',
      light: '#6F96D1',
    },
    opacity: {
      main: '#fdfdfd59',
      contrastText: '#fff',
    },
    secondary: {
      ...grey,
      main: grey['900'],
      light: grey['400'],
    },
    neutral: {
      main: '#F4F5F6',
    },
    background: {
      default: '#EDF0F5',
      paper: '#fff',
    },
    formLabel: '#000000de',
    // link: '#1a0dab',
  },
};

const DARK = {
  ...common,
  palette: {
    mode: THEMES.DARK,
    accepted: common.palette.augmentColor({
      color: {
        main: '#d5b50a',
        contrastText: '#000',
      },
      name: 'accepted',
    }),
    sidebar: {
      background: 'linear-gradient(181deg, #101E52 0.95%, #081851 27.18%, #153E66 71.39%, #163C61 99.53%)',
      color: '#BAD6EB',
      activeColor: 'secondary.main',
      active: '#4F6595',
    },
    spaceSelector: '#222b36',
    background: {
      default: '#1e2732',
      paper: '#222b36',
    },
    primary: {
      main: '#90caf9', // '#a4328a',
      light: '#363e48',
    },
    opacity: {
      main: '#fdfdfd59',
      contrastText: '#fff',
    },
    secondary: {
      ...grey,
      main: grey['400'],
      light: grey['800'],
    },
    neutral: {
      main: grey['800'],
    },
    formLabel: '#fff',
    link: '#8ab4f8',
  },
};

export const THEME_SETTING = {
  [THEMES.LIGHT]: LIGHT,
  [THEMES.DARK]: DARK,
};
