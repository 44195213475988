import { createContext, ReactNode, useContext, useEffect, useMemo } from 'react';
import { useLocalStorage } from '@dfl/hook-utils';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';

type Props = {
  children: ReactNode;
};

interface Context {
  data: string;
  storeData: any;
}

export const TRANSLATION_KEY = 'translation-language';

export const TranslationContext = createContext<Context>({
  data: '',
  storeData: () => {},
});

const TranslationProvider = ({ children }: Props) => {
  const { i18n } = useTranslation('locales');
  const locale = i18n?.language;
  const { data, storeData } = useLocalStorage(TRANSLATION_KEY, locale);
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries([data]);
  }, [data]);

  return (
    <TranslationContext.Provider
      value={{
        data,
        storeData,
      }}
    >
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslationLanguage = () => {
  const { data, storeData } = useContext(TranslationContext);

  const finalData = useMemo(() => data.replaceAll('"', ''), [data]);

  return {
    data: finalData,
    storeData,
  };
};

export default TranslationProvider;
